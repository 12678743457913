import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';

import { PUBLIC_STUDIO_REGISTER_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import { redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(async (_to: RouteLocationNormalized) => {
  const userStore = useUserStore();
  const { isMember } = storeToRefs(userStore);
  if (isMember.value) {
    return;
  }
  return await redirectTo(PUBLIC_STUDIO_REGISTER_PAGE_URL, { toType: 'name' });
});
